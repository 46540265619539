import { GuestEventHotelRoomBlocksQuery, ReservedRoomBlockStatus } from '@graphql/generated';

export type AccommodationPlace = NonNullable<NonNullable<GuestEventHotelRoomBlocksQuery['event']>['accommodationPlaces'][number]>;

export type HotelRoomBlocks = NonNullable<NonNullable<GuestEventHotelRoomBlocksQuery['event']>['hotelRoomBlocks']>;

export type ReservedRoomBlocks = NonNullable<HotelRoomBlocks['reservedRoomBlocks']>;

export type ReservedRoomBlock = NonNullable<ReservedRoomBlocks[number]>;

export enum AccommodationCombinedType {
  Roomblock = 'Roomblock',
  Hotel = 'Hotel',
  Custom = 'Custom'
}

export interface AccommodationCombined {
  id: string;
  type: AccommodationCombinedType;

  //   General Information
  displayName?: Maybe<string>;
  address?: Maybe<string>;
  city?: Maybe<string>;
  country?: Maybe<string>;
  state?: Maybe<string>;
  postalCode?: Maybe<string>;
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;

  description?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  url?: Maybe<string>;

  joyPlaceId?: Maybe<string>;

  //   Provider Information
  googlePlaceId?: Maybe<string>;
  zhId?: Maybe<string>;

  //   Extra Information
  pricePerNight?: Maybe<number>;
  bookingUrl?: Maybe<string>;
  cutoffDate?: Maybe<string>;
  checkOutDate?: Maybe<string>;
  checkInDate?: Maybe<string>;
  strikeoutPricePerNight?: Maybe<number>;
  numberOfRoomsBooked?: Maybe<number>;
  numberOfRoomsGuestBooked?: Maybe<number>;

  //   Customm Properties
  isFavorite?: Maybe<boolean>;
  starRating?: Maybe<number>;

  photo: Maybe<{
    id?: Maybe<string>;
    url?: Maybe<string>;
  }>;

  status?: Maybe<ReservedRoomBlockStatus>;
}
