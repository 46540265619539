import { AccommodationPlaceType } from '@graphql/generated';
import { AccommodationCombined, AccommodationCombinedType, AccommodationPlace, ReservedRoomBlock } from './Accommodation.types';

export const RoomBlockToAccommodationCombined = (rooomBlock: ReservedRoomBlock) => {
  return {
    id: rooomBlock.id,
    type: AccommodationCombinedType.Roomblock,

    //   General Information
    displayName: rooomBlock.displayName,
    address: rooomBlock.address,
    city: undefined,
    country: undefined,
    state: undefined,
    postalCode: undefined,
    latitude: rooomBlock.latitude,
    longitude: rooomBlock.longitude,

    description: rooomBlock.note,
    email: undefined,
    phone: undefined,
    url: undefined,

    // JoyPlaceID
    joyPlaceId: null,

    //   Provider Information
    googlePlaceId: undefined,
    zhId: undefined,

    //   Extra Information
    pricePerNight: rooomBlock.pricePerNight,
    bookingUrl: rooomBlock.bookingUrl,
    cutoffDate: rooomBlock.cutoffDate,
    checkOutDate: rooomBlock.checkOutDate,
    checkInDate: rooomBlock.checkInDate,
    strikeoutPricePerNight: rooomBlock.strikeoutPricePerNight,
    numberOfRoomsBooked: rooomBlock.numberOfRoomsBooked,
    numberOfRoomsGuestBooked: rooomBlock.numberOfRoomsGuestBooked,

    //   Customm Properties
    isFavorite: rooomBlock.isFavorite,
    starRating: rooomBlock.starRating,

    photo: rooomBlock.photo
  } as AccommodationCombined;
};

export const AccommodationPlaceToAccommodationCombined = (accommodationPlace: AccommodationPlace) => {
  return {
    id: accommodationPlace.id,
    type: accommodationPlace.type === AccommodationPlaceType.hotel ? AccommodationCombinedType.Hotel : AccommodationCombinedType.Custom,

    //   General Information
    displayName: accommodationPlace.joyPlace.name,
    address: accommodationPlace.joyPlace.address,
    city: accommodationPlace.joyPlace.city,
    country: accommodationPlace.joyPlace.country,
    state: accommodationPlace.joyPlace.state,
    postalCode: accommodationPlace.joyPlace.postalCode,
    latitude: accommodationPlace.joyPlace.lat,
    longitude: accommodationPlace.joyPlace.long,

    description: accommodationPlace.userNote,
    email: accommodationPlace.joyPlace.email,
    phone: accommodationPlace.joyPlace.phone,
    url: accommodationPlace.joyPlace.url,

    // JoyPlaceID
    joyPlaceId: accommodationPlace.joyPlace.id,

    //   Provider Information
    googlePlaceId: accommodationPlace.joyPlace.googlePlaceId,
    zhId: accommodationPlace.joyPlace.zhId,

    //   Extra Information
    pricePerNight: undefined,
    bookingUrl: undefined,
    cutoffDate: undefined,
    checkOutDate: undefined,
    checkInDate: undefined,
    strikeoutPricePerNight: undefined,
    numberOfRoomsBooked: undefined,
    numberOfRoomsGuestBooked: undefined,

    //   Customm Properties
    isFavorite: undefined,
    starRating: undefined,

    photo: accommodationPlace.displayPhoto
  };
};
