import { useApolloClient } from '@apollo/client';
import { JoyPlaceByIdDocument, JoyPlaceByIdQuery, useJoyPlaceCreateMutation } from '@graphql/generated';
import { AnalyticsContext } from '@shared/core';
import { useContext } from 'react';

export interface JoyPlace {
  address: string;
  city: string;
  country: string;
  description: string;
  email: string;
  googlePlaceId: string;
  id: string;
  lat: number;
  long: number;
  name: string;
  phone: string;
  postalCode: string;
  state: string;
  url: string;
  zhId: string;
  photoUrl?: string | null;
}

/**
 * @description
 * This is a REST API hook for the JoyPlace API.
 */
export const useJoyPlace = () => {
  const [_createJoyPlace] = useJoyPlaceCreateMutation();
  const analytics = useContext(AnalyticsContext);
  const client = useApolloClient();

  const getJoyPlaceById: (id: string) => Promise<JoyPlace | null> = async id => {
    return new Promise(resolve => {
      client
        .query<JoyPlaceByIdQuery>({
          query: JoyPlaceByIdDocument,
          variables: {
            joyPlaceByIdId: id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const joyPlace = response.data.joyPlaceById as JoyPlace;
          resolve(joyPlace);
        })
        .catch(err => {
          analytics.track({
            category: 'joyPlace',
            action: 'getJoyPlaceId',
            actionType: 'error',
            extraInfo: { id, error: err }
          });
          resolve(null);
        });
    });
  };

  const createJoyPlace: (joyPlace: Partial<JoyPlace>) => Promise<string | null> = async joyPlace => {
    return new Promise(resolve => {
      _createJoyPlace({ variables: { payload: joyPlace } })
        .then(response => {
          resolve(response.data?.joyPlaceCreate.id || null);
        })
        .catch(err => {
          analytics.track({
            category: 'joyPlace',
            action: 'createJoyPlace',
            actionType: 'error',
            extraInfo: { ...joyPlace, error: err }
          });
          resolve(null);
        });
    });
  };

  return { createJoyPlace, getJoyPlaceById };
};
