import { StationeryPrintOrderStatusEnum, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { Format } from './routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { withWindow } from '@shared/utils/withWindow';

const category = 'messaging.card';
const PUBLIC_SHOP_CATEGORY = 'marketing.messaging.card';

interface CardDraftCreatedArgs {
  page?: 'pdpModal';
  draftId: string;
  themeId: string;
  stationeryTemplateCategory: StationeryTemplateCategoryEnum;
  existingDraftsCount: number;
  subtotalInMinorUnits?: number;
  isDigital?: boolean;
}

interface DraftsGalleryTileInteractedArgs {
  draftId: string;
  templateCategory: StationeryTemplateCategoryEnum;
  themeId: string;
}

interface SharedOrderDetailsArgs {
  orderId: string;
  orderNumber: string;
  themeId: string;
  templateCategory: StationeryTemplateCategoryEnum;
  orderStatus: StationeryPrintOrderStatusEnum;
}

export interface ExitCardPdpModalArgs {
  name: 'breadcrumbsRootLink' | 'breadcrumbsCategoryLink' | 'closeButton';
}

interface GoToOrderDetailsClickArgs extends SharedOrderDetailsArgs {}

interface ContactSupportButtonInteractedArgs extends SharedOrderDetailsArgs {}

interface TrackOrderButtonInteractedArgs extends SharedOrderDetailsArgs {
  source: 'orderDetails' | 'orderHistory';
}

interface ReorderButtonInteractedArgs extends SharedOrderDetailsArgs {
  source: 'orderDetails' | 'orderHistory';
  eventId: string;
  ownerId: string;
  orderQuantity: number;
  orderPriceInMinorUnits: number;
  draftId: string;
}

const _isInPublicShop = () => {
  return withWindow(global => {
    return !global.location.pathname.match(/^\/(.*)\/edit\/cards(\/.*)?$/gi);
  }, false);
};

const getPagePrefix = () => {
  return _isInPublicShop() ? 'marketing' : 'admin';
};

const getActionCategory = () => {
  return _isInPublicShop() ? PUBLIC_SHOP_CATEGORY : category;
};

const cardTelemetry = createTelemetryObject({
  pages: {
    homeRoute: () => ({
      category,
      page: 'home',
      pagePrefix: getPagePrefix()
    }),
    draftsRoute: () => ({
      category,
      page: 'drafts',
      pagePrefix: getPagePrefix()
    }),
    ordersRoute: () => ({
      category,
      page: 'orders',
      pagePrefix: getPagePrefix()
    }),
    orderDetailsRoute: () => ({
      category,
      page: 'orderDetails',
      pagePrefix: getPagePrefix()
    }),
    existingDraftsWarningDialog: () => ({
      category,
      page: 'existingDraftsWarningDialog',
      pagePrefix: getPagePrefix()
    }),
    restrictedInternationalShippingWarningDialog: () => ({
      category,
      page: 'restrictedInternationalShippingWarningDialog',
      pagePrefix: getPagePrefix()
    }),
    productPdpModal: () => ({
      category,
      page: 'pdpModal',
      pagePrefix: getPagePrefix()
    }),
    orderConfirmationConciergeRoute: () => ({
      category,
      page: 'orderConfirmationConcierge',
      pagePrefix: getPagePrefix()
    })
  },
  actions: {
    cardDraftCreated: ({ page, ...extraInfo }: CardDraftCreatedArgs) => ({
      category: getActionCategory(),
      action: 'CardDraftCreated',
      page,
      extraInfo: extraInfo
    }),
    primaryNavInteracted: (destination: 'home' | 'drafts' | 'orders') => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'primaryNav_goToRoute',
        destination
      }
    }),
    existingDraftsWarningDialogViewAllOrEditExistingButtonInteracted: (args: {
      themeId: string;
      variant: 'viewAll' | 'editExisting';
      templateCategory: StationeryTemplateCategoryEnum;
      existingTemplateDraftsCount: number;
    }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `existingDraftsWarningDialog_${args.variant}`,
        themeId: args.themeId,
        existingTemplateDraftsCount: args.existingTemplateDraftsCount
      }
    }),
    existingDraftsWarningDialogCreateNewDraftButtonInteracted: (args: {
      themeId: string;
      templateCategory: StationeryTemplateCategoryEnum;
      existingTemplateDraftsCount: number;
    }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'existingDraftsWarningDialog_createNewDraft',
        themeId: args.themeId,
        stationeryTemplateCategory: args.templateCategory,
        existingTemplateDraftsCount: args.existingTemplateDraftsCount
      }
    }),
    restrictedInternationalShippingWarningDialogConfirmClicked: (args: { themeId: string }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'restrictedInternationalShippingWarningDialog_confirmClicked',
        themeId: args.themeId
      }
    }),
    restrictedInternationalShippingWarningDialogDismiss: (args: { themeId: string }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'restrictedInternationalShippingWarningDialog_dismiss',
        themeId: args.themeId
      }
    }),
    restrictedInternationalShippingWarningDialogCreateEcardClicked: (args: {
      themeId: string;
      dialogId: 'restrictedInternationalShippingWarningDialog' | 'shippingAddressDialog' | 'checkoutErrorDialog';
    }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `${args.dialogId}_createEcardClicked`,
        themeId: args.themeId
      }
    }),
    viewCategoryDesigns: (templateCategory: StationeryTemplateCategoryEnum) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'categoryNav_viewCategoryDesigns',
        stationeryTemplateCateogry: templateCategory
      }
    }),
    draftsGalleryTileInteracted: (args: DraftsGalleryTileInteractedArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'draftsGallery_editDraft',
        stationeryDraftId: args.draftId,
        themeId: args.themeId,
        stationeryTemplateCategory: args.templateCategory
      }
    }),

    // Order Details related telemetry
    /**
     * Meant for order details page
     */
    goToOrderDetailsClick: (args: GoToOrderDetailsClickArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'orderHistory_orderSummary',
        orderId: args.orderId,
        orderNumber: args.orderNumber,
        stationeryTemplateCategory: args.templateCategory,
        themeId: args.themeId,
        orderStatus: args.orderStatus
      }
    }),
    /**
     * Meant for order details page
     */
    contactSupportButtonInteracted: (args: ContactSupportButtonInteractedArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'orderDetails_contactSupport',
        orderId: args.orderId,
        orderNumber: args.orderNumber,
        stationeryTemplateCategory: args.templateCategory,
        themeId: args.themeId,
        orderStatus: args.orderStatus
      }
    }),

    /**
     * Meant for order details page
     */
    trackOrderButtonInteracted: (args: TrackOrderButtonInteractedArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `${args.source}_trackOrder`,
        orderId: args.orderId,
        orderNumber: args.orderNumber,
        stationeryTemplateCategory: args.templateCategory,
        themeId: args.themeId,
        orderStatus: args.orderStatus
      }
    }),
    reorderButtonInteracted: (args: ReorderButtonInteractedArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `${args.source}_reorderButton`,
        orderId: args.orderId,
        orderNumber: args.orderNumber,
        stationeryTemplateCategory: args.templateCategory,
        themeId: args.themeId,
        orderStatus: args.orderStatus,
        eventId: args.eventId,
        ownerId: args.ownerId,
        orderQuantity: args.orderQuantity,
        orderPriceInMinorUnits: args.orderPriceInMinorUnits,
        draftId: args.draftId
      }
    }),
    /**
     * Meant for order details page
     */
    visitCourierPageToResolveIssueButtonInteracted: (args: ContactSupportButtonInteractedArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'orderDetails_visitCourierPageToResolveIssue',
        orderId: args.orderId,
        orderNumber: args.orderNumber,
        stationeryTemplateCategory: args.templateCategory,
        themeId: args.themeId,
        orderStatus: args.orderStatus
      }
    }),

    paperlustConciergeServiceSectionTileInteracted: (args: { suite: string; cost: string; sortIndex: number; orderOfItems: string[] }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'paperlustConciergeService_carousel_tile',
        suite: args.suite,
        cost: args.cost,
        sortIndex: args.sortIndex,
        orderOfItems: args.orderOfItems
      }
    }),

    paperlustConciergeServiceDialogButtonInteracted: (args: { type: 'notifyMe' | 'orderNow'; suite: string; cost: string; sortIndex: number; orderOfItems: string[] }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: args.type === 'notifyMe' ? 'paperlustConciergeService_dialog_notifyMeCta' : 'paperlustConciergeService_dialog_orderNowCta',
        suite: args.suite,
        cost: args.cost,
        sortIndex: args.sortIndex,
        orderOfItems: args.orderOfItems
      }
    }),
    designGalleryTileClicked: (args: { themeId: string; templateCategory: StationeryTemplateCategoryEnum; format: Format }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'designsGallery_tileClicked',
        themeId: args.themeId,
        stationeryTemplateCategory: args.templateCategory,
        format: args.format
      }
    }),
    filterFacetClicked: (args: { facet: string; code: string; isChecked: boolean }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'designsGallery_filterFacetClicked',
        facet: args.facet,
        code: args.code,
        isChecked: args.isChecked
      }
    }),
    //=================================
    // PDP Modal
    //=================================
    exitCardPdpModal: (args: ExitCardPdpModalArgs) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      page: 'pdpModal',
      extraInfo: {
        name: `pdpModal_exit_${args.name}`
      }
    }),
    pdpCustomizeNowClicked: (args: { themeId: string; supportedFormats: Format; format: 'paper' | 'digital' | 'premiumDigital' }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'pdpModal_customizeNowClicked',
        themeId: args.themeId,
        supportedFormats: args.supportedFormats,
        selectedFormat: args.format
      }
    }),
    pdpFormatControlClicked: (args: { themeId: string; format: 'paper' | 'digital' }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'pdpModal_formatControlClicked',
        themeId: args.themeId,
        format: args.format
      }
    }),
    goToCheckoutClicked: (args: { designSet: string; quantity: number }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'conciergeReviewScreen_goToCheckoutClicked',
        designSet: args.designSet,
        quantity: args.quantity
      }
    }),
    provideCardDetailsClicked: (args: { orderNumber: string }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'conciergePostCheckoutScreen_provideDetailsClicked',
        orderNumber: args.orderNumber
      }
    }),
    editOrderCtaInteracted: (args: {
      source: 'orderDetails' | 'orderHistory';
      themeId: string;
      orderNumber: string;
      stationeryTemplateCategory: StationeryTemplateCategoryEnum;
    }) => ({
      category: getActionCategory(),
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `${args.source}_editOrderCta`,
        themeId: args.themeId,
        orderNumber: args.orderNumber,
        stationeryTemplateCategory: args.stationeryTemplateCategory
      }
    })
  }
});

export const { TelemetryProvider, useTelemetry: useCardTelemetry, enrichTelemetryExtraInfo: enrichCardTelemetryExtraInfo } = createTelemetry(cardTelemetry, undefined, undefined, {
  stationeryTemplateCategory: null
} as {
  stationeryTemplateCategory: StationeryTemplateCategoryEnum | null;
});
